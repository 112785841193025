import "./assets/bootstrap-reboot.min.css"
import "./assets/bootstrap-grid.min.css"

import "./assets/bootstrap.min.css"

import "./assets/mbr-additional.css"
import "./assets/style.css"
import "./assets/styles.css"
import "./assets/style_1.css"
import "./assets/animate.css"
import "./assets/animate.css"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useState } from "react"
import { firebaseDb } from "./../configuration-firebase";
import javaBurnJson from "../product-jsons/javaburn.json"
import {
    collection,
    addDoc,

} from "firebase/firestore";
import { useLocation, useParams } from "react-router-dom"
export const Template1 = () => {
    const usersCollectionRef = collection(firebaseDb, "emails");

    const [showDialog, setShowDialog] = useState(false);
    const [formData, setFormData] = useState({
        email: "",
        name: ""
    });

    const { product } = useParams();
   
    console.log(product);
    let productInfoJson = {};
    if (product === "javaburn") {
        productInfoJson = javaBurnJson;
    }

    const { search ,pathname} = useLocation();
    console.log(pathname)
    console.log(search);
    console.log(javaBurnJson);
    const [formDataError, setFormDataError] = useState({
        email: "",
        name: ""
    })
    const toggleDialog = () => {
        if(pathname.startsWith("/offers") || pathname.startsWith("offers")){
            moveToClickBank();
            return;
        }
        setFormData({
            email: "",
            name: ""
        })
        setShowDialog(!showDialog)
    }

    const updateForm = (event) => {
        // debugger
        setFormDataError({
            name: ""
            , email: ""
        })
        formData[event.target.name] = event.target.value

        setFormData(formData)

    }

    const handlesubmitt = async () => {
        console.log("submitt")
        if (!formData.name || formData.name === "") {
            setFormDataError({
                name: "Name is required!"
            })
            return;
        }
        if (!formData.email || formData.email === "") {
            setFormDataError({
                email: "Email is required!"
            })
            return;
        }
        // if(!validateEmail(formData.validateEmail)){
        //     setFormDataError({
        //         email:"not a valid email!"
        //     })
        //     return;
        // }
        const clickUrl = productInfoJson.clikcUrl+search.substr(search.indexOf("?"));

        await addDoc(usersCollectionRef, { 
            name: formData.name,
             email: formData.email.toLowerCase() , 
             date: new Date(), 
             emailCount:0,
             processed:false,
            clikcUrl:clickUrl,
            product: product
        });
        moveToClickBank()
    }

    const moveToClickBank = ()=>{
        const clickUrl = productInfoJson.clikcUrl+search.substr(search.indexOf("?"));
        window.location.href = clickUrl

    }

  
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    return <div style={{ textAlign: "justify" }}>
        <Modal show={showDialog} onHide={toggleDialog}>

            <Modal.Header closeButton style={{ background: "#dd6d00", color: "white" }} >
                <Modal.Title style={{ fontWeight: "bold" }}>Subscribe now</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={handlesubmitt}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="name" required name="name" onChange={updateForm} isInvalid={formDataError['name'] !== ""} />
                        <Form.Control.Feedback type="invalid">{formDataError['name']} </Form.Control.Feedback>

                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="email" required name="email" onChange={updateForm} isInvalid={formDataError['email'] !== ""} />
                        <Form.Control.Feedback type="invalid">{formDataError['email']} </Form.Control.Feedback>

                    </Form.Group>

                </Form>
            </Modal.Body>

            <Modal.Footer style={{ background: "#dd6d00" }}>
                <div class="navbar-buttons mbr-section-btn" onClick={handlesubmitt}><a class="btn btn-warning display-4" rel="nofollow"><span class="socicon socicon-bale mbr-iconfont mbr-iconfont-btn" style={{ fontSize: "20px" }}></span>ORDER NOW</a></div>
            </Modal.Footer>

        </Modal>
        <link rel="preload" as="font" href="https://wpsuperlinks.top/paginas-produtores/icones/fonts/icomoon2.woff" />
        <link rel="preload" as="font" href="https://wpsuperlinks.top/paginas-produtores/icones/fonts/icomoon2.ttf" /
        >  <link rel="preload" as="font" href="https://wpsuperlinks.top/paginas-produtores/icones/fonts/icomoon2.eot" />
        <link rel="preload" as="font" href="https://wpsuperlinks.top/paginas-produtores/icones/fonts/icomoon.woff" />
        <link rel="preload" as="font" href="https://wpsuperlinks.top/paginas-produtores/icones/fonts/icomoon.ttf" />
        <link rel="preload" as="font" href="https://wpsuperlinks.top/paginas-produtores/icones/fonts/icomoon.eot" />
        <link rel="preload" as="font" href="https://wpsuperlinks.top/paginas-produtores/icones/fonts/garantias.woff" />
        <link rel="preload" as="font" href="https://wpsuperlinks.top/paginas-produtores/icones/fonts/garantias.ttf" />
        <link rel="preload" as="font" href="https://wpsuperlinks.top/paginas-produtores/icones/fonts/garantias.eot" />
        <link rel="preload" as="font" href="https://wpsuperlinks.top/paginas-produtores/icones/fonts/elementskit.woff" />
        <link rel="preload" as="font" href="https://wpsuperlinks.top/paginas-produtores/icones/fonts/eicons.woff2" />
        <link rel="preload" as="font" href="https://wpsuperlinks.top/paginas-produtores/icones/fonts/eicons.eot" />
        <link rel="preload" as="font" href="https://wpsuperlinks.top/paginas-produtores/icones/fonts/dashicons.eot" />
        <link rel="preload" as="font" href="https://wpsuperlinks.top/paginas-produtores/icones/fonts/dashicons.ttf" />

        {productInfoJson.header &&
            <section data-bs-version="5.1" class="menu menu2 cid-tmAYGHW7QI" once="menu" id="menu2-29">

                <nav class="navbar navbar-dropdown navbar-expand-lg">
                    <div class="container">
                        <div class="navbar-brand">

                            <span class="navbar-caption-wrap"><a class="navbar-caption text-white text-primary display-2" href="/">{productInfoJson.header.name}</a></span>
                        </div>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-bs-toggle="collapse" data-target="#navbarSupportedContent" data-bs-target="#navbarSupportedContent" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <div class="hamburger">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav nav-dropdown" data-app-modern-menu="true">
                                <li class="nav-item"><a class="nav-link link text-white text-primary display-4" href={productInfoJson.header.question.link}>{productInfoJson.header.question.text}</a>
                                </li><li class="nav-item"><a class="nav-link link text-white text-primary display-4" href={productInfoJson.header.priceLink.link}>{productInfoJson.header.priceLink.text}</a></li></ul>

                            <div class="navbar-buttons mbr-section-btn"><a class="btn btn-warning display-4" onClick={toggleDialog} rel="nofollow"><span class="socicon socicon-bale mbr-iconfont mbr-iconfont-btn" style={{ fontSize: "20px" }}></span>ORDER NOW</a></div>
                        </div>
                    </div>
                </nav>
            </section>
        }
        {productInfoJson.mainSection &&
            <section data-bs-version="5.1" class="header14 cid-tmAWnNTbYr" id="header14-28">

                <div class="container">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-12 col-md-6 image-wrapper">
                            <a onClick={toggleDialog} rel="nofollow" class="animate__animated animate__delay-1s animate__fadeInUp">


                                <img src={require(`../products-assets/${productInfoJson.mainSection.image}`)} alt={productInfoJson.name} width="628" height="479"
                                />
                            </a>

                        </div>
                        <div class="col-12 col-md">
                            <div class="text-wrapper">
                                <h1 class="mbr-section-title mbr-fonts-style mb-3 display-1 animate__animated animate__delay-1s animate__fadeInUp"><strong>{productInfoJson.name}</strong></h1>
                                <p class="mbr-text mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp">
                                    <br />
                                    {productInfoJson.mainSection.paragraph1} </p>
                                {productInfoJson.mainSection.li && productInfoJson.mainSection.li.length > 0 &&
                                    <ul>
                                        {productInfoJson.mainSection.li.map((text) => <li class="animate__animated animate__delay-1s animate__fadeInUp">{text}</li>)}

                                    </ul>
                                }

                                <p class="mbr-text mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp"><strong><u>{productInfoJson.mainSection.paragraph2} </u><br /></strong><br /></p>
                                <div class="mbr-section-btn mt-3"><a class="btn btn-warning display-7 animate__animated animate__delay-1s animate__fadeInUp" onClick={toggleDialog} rel="nofollow"><font face="Moririse2"><br /></font>GET A DISCOUNT</a> <a class="btn btn-primary-outline display-7 animate__animated animate__delay-1s animate__fadeInUp" onClick={toggleDialog} rel="nofollow"><span class="mobi-mbri mobi-mbri-arrow-next mbr-iconfont mbr-iconfont-btn" style={{ fontSize: "15px" }}></span><font face="Moririse2"><br /></font>OFFICIAL WEBSITE</a></div>
                                <br /><br />

                                <div itemprop="itemReviewed" itemscope="" itemtype="https://schema.org/Product">
                                    <span itemprop="name">{productInfoJson.name}</span>
                                    <div class="ratings" itemscope="" itemtype="http://schema.org/AggregateRating" itemprop="aggregateRating">
                                        <div class="rating-box" style={{ float: "left", marginRight: "5px" }}>
                                            <div class="rating" style={{ width: "100%" }}></div>
                                        </div>
                                        <div class="r-lnk">
                                            <span itemprop="ratingValue">5.0</span>
                                            <span> / </span>
                                            <span itemprop="reviewCount">2075</span>
                                            Reviews </div>
                                    </div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        }{
            productInfoJson.featureSection && <>

                <section data-bs-version="5.1" class="content4 cid-tkw43LLXRT" id="content4-1a">




                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="title col-md-12 col-lg-12">
                                <h2 class="mbr-section-title mbr-fonts-style align-center mb-4 display-2 animate__animated animate__delay-1s animate__fadeInUp"><strong>{ productInfoJson.featureSection.title}</strong></h2>


                            </div>
                        </div>
                    </div>
                </section>

                <section data-bs-version="5.1" class="team1 cid-tkw4m1pY9d" id="team1-1b">




                    <div class="container">
                        <div class="row justify-content-center">

                            <div class="col-sm-6 col-lg-3">
                                <div class="card-wrap">
                                    <div class="image-wrap">
                                        <img src={require("./assets/untitled-200-120-px-2-500x300.png")} alt="Made In USA" class="animate__animated animate__delay-1s animate__fadeInUp" />
                                    </div>
                                    <div class="content-wrap">
                                        <h5 class="mbr-section-title card-title mbr-fonts-style align-center m-0 display-5 animate__animated animate__delay-1s animate__fadeInUp"><strong>Made In USA</strong></h5>

                                        <p class="card-text mbr-fonts-style align-center display-7 animate__animated animate__delay-1s animate__fadeInUp">
                                            Java Burn dietary supplement is manufactured in a US-based facility.
                                        </p>


                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-3">
                                <div class="card-wrap">
                                    <div class="image-wrap">
                                        <img src={require("./assets/untitled-200-120-px-1-500x300.png")} alt="GMP Certified" class="animate__animated animate__delay-1s animate__fadeInUp" />
                                    </div>
                                    <div class="content-wrap">
                                        <h5 class="mbr-section-title card-title mbr-fonts-style align-center m-0 display-5 animate__animated animate__delay-1s animate__fadeInUp"><strong>GMP Certified</strong></h5>

                                        <p class="card-text mbr-fonts-style align-center display-7 animate__animated animate__delay-1s animate__fadeInUp">
                                            This product has been certified under Good Manufacturing Practice standards.
                                        </p>


                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-3">
                                <div class="card-wrap">
                                    <div class="image-wrap">
                                        <img src={require("./assets/untitled-200-120-px-500x300.png")} alt="FDA Approved" class="animate__animated animate__delay-1s animate__fadeInUp" />
                                    </div>
                                    <div class="content-wrap">
                                        <h5 class="mbr-section-title card-title mbr-fonts-style align-center m-0 display-5 animate__animated animate__delay-1s animate__fadeInUp"><strong>FDA Approved</strong></h5>

                                        <p class="card-text mbr-fonts-style align-center display-7 animate__animated animate__delay-1s animate__fadeInUp">Java Burn is formulated in a FDA registered facility which adheres to strict FDA regulations.
                                        </p>


                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-3">
                                <div class="card-wrap">
                                    <div class="image-wrap">
                                        <img src={require("./assets/untitled-200-120-px-3-500x300.png")} alt="Natural Product" class="animate__animated animate__delay-1s animate__fadeInUp" />
                                    </div>
                                    <div class="content-wrap">
                                        <h5 class="mbr-section-title card-title mbr-fonts-style align-center m-0 display-5 animate__animated animate__delay-1s animate__fadeInUp"><strong>100% Natural</strong></h5>

                                        <p class="card-text mbr-fonts-style align-center display-7 animate__animated animate__delay-1s animate__fadeInUp">
                                            We are proud to say that Java Burn is an All Natural, Non-GMO and Gluten-Free supplement.
                                        </p>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>}
        <section data-bs-version="5.1" class="content4 cid-tkwapwRYSb" id="content4-1h">




            <div class="container">
                <div class="row justify-content-center">
                    <div class="title col-md-12 col-lg-12">
                        <h3 class="mbr-section-title mbr-fonts-style align-center mb-4 display-2 animate__animated animate__delay-1s animate__fadeInUp"><strong>Java Burn Reviews</strong></h3>


                    </div>
                </div>
            </div>
        </section>

        <section data-bs-version="5.1" class="team1 cid-tkwaK0DLYn" id="team1-1i">


            <div class="JCB container">

                <div class="media-container-row">
                    <div class="mbr-testimonial p-3 align-center col-12 col-md-6 col-lg-4">
                        <div class="panel-item p-3 JCB_User">
                            <div class="card-block animate__animated animate__delay-1s animate__fadeInUp">
                                <div class="testimonial-photo">
                                    <img src={require("./assets/m1-730x383.png")} alt="Review Image 1" />
                                </div>

                                <p class="StarBox"><img src={require("./assets/stars-3-400x60.png")} alt="Star Rating" /></p>
                                <p style={{ fontSize: "25px" }}><strong>Verified Purchase&nbsp;</strong>✅</p>
                                <p class="mbr-text mbr-fonts-style display-7">Husband tried last month and lost 15 pounds. Make sure you use a directed!! I am anxious to get started. He says it curbed his appetite immensely!!<br />
                                </p>
                            </div>
                            <div class="card-footer">
                                <div class="mbr-author-name mbr-bold mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp"><em class="animate__animated animate__delay-1s animate__fadeInUp">Charlotte K. - Los Angeles, USA</em></div>

                            </div>
                        </div>
                    </div>

                    <div class="mbr-testimonial p-3 align-center col-12 col-md-6 col-lg-4">
                        <div class="panel-item p-3 JCB_User">
                            <div class="card-block animate__animated animate__delay-1s animate__fadeInUp">
                                <div class="testimonial-photo">
                                    <img src={require("./assets/w1-1-730x383.png")} alt="Review Image 2" />
                                </div>
                                <p class="StarBox"><img src={require("./assets/stars-3-400x60.png")} alt="Star Rating" /></p>
                                <p style={{ fontSize: "25px" }}><strong>Verified Purchase&nbsp;</strong>✅</p>
                                <p class="mbr-text mbr-fonts-style display-7">I was told about this from my sister's boyfriend who loves this coffee. I ordered it to help with weight loss. I love coffee and drink it daily so why not get some that will help me daily.</p>
                            </div>
                            <div class="card-footer">
                                <div class="mbr-author-name mbr-bold mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp"><em class="animate__animated animate__delay-1s animate__fadeInUp">Ellen K. - Florida, USA</em></div>

                            </div>
                        </div>
                    </div>

                    <div class="mbr-testimonial p-3 align-center col-12 col-md-6 col-lg-4">
                        <div class="panel-item p-3 JCB_User">
                            <div class="card-block animate__animated animate__delay-1s animate__fadeInUp">
                                <div class="testimonial-photo">
                                    <img src={require("./assets/m3-730x383.png")} alt="Review Image 3" />
                                </div>
                                <p class="StarBox"><img src={require("./assets/stars-3-400x60.png")} alt="Star Rating" /></p>
                                <p style={{ fontSize: "25px" }}><strong>Verified Purchase&nbsp;</strong>✅</p>

                                <p class="mbr-text mbr-fonts-style display-7">It has been two months since I started incorporating the Java Burn dietary supplement into my morning coffee routine. Almost immediately, I noticed an increase in energy and vitality. As the weeks went by, I could see a noticeable improvement in my metabolism. And after two months, I have seen a significant decrease in my weight. <br /></p>
                            </div>
                            <div class="card-footer">
                                <div class="mbr-author-name mbr-bold mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp"><em class="animate__animated animate__delay-1s animate__fadeInUp">Jay M. - Chicago, USA</em></div>

                            </div>
                        </div>
                    </div>






                </div>
            </div>

        </section>

        <section data-bs-version="5.1" class="content4 cid-tjtwnSRDdB" id="content4-4">




            <div class="container">
                <div class="row justify-content-center">
                    <div class="title col-md-12 col-lg-12">
                        <h3 class="mbr-section-title mbr-fonts-style align-center mb-4 display-2 animate__animated animate__delay-1s animate__fadeInUp"><strong>What Is Java Burn?</strong></h3>


                    </div>
                </div>
            </div>
        </section>

        <section data-bs-version="5.1" class="header11 cid-tjtwWIp2h5" id="header11-5">






            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-5 image-wrapper">
                        <img class="w-100 animate__animated animate__delay-1s animate__fadeInUp" src={require("./assets/javaburn-buy.png")} alt="Java Burn buy" />
                    </div>
                    <div class="col-12 col-md">
                        <div class="text-wrapper text-center">

                            <p class="mbr-text mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp">

                                Are you looking for a natural and safe way to boost your energy and improve your health?
                                <br /><br />
                                Look no further than Java Burn, the dietary supplement that is taking the health and wellness world by storm. This 100% safe and natural formula is created to enhance metabolism, reduce hunger and cravings, and boost energy levels.
                                <br /><br />
                                With Java Burn, you can experience the benefits of a faster metabolism, which can help you burn fat and lose weight more effectively.
                                <br /><br />
                                This supplement contains natural ingredients such as chromium, green tea extract, and L-Theanine, which are known to be effective in supporting weight loss.  Not only that, but it is also known to provide a natural energy boost without any harmful side effects.
                                <br /><br />
                                So, if you are looking for an effective and natural way to enhance your coffee and support your weight loss journey, Java Burn is definitely worth a try!

                                <br />
                            </p>
                            <div class="mbr-section-btn mt-3"><a class="btn btn-warning display-7 animate__animated animate__delay-1s animate__fadeInUp" onClick={toggleDialog} rel="nofollow"><span class="mobi-mbri mobi-mbri-arrow-next mbr-iconfont mbr-iconfont-btn" style={{ fontSize: "15px" }}></span>SHOP NOW</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section data-bs-version="5.1" class="content4 cid-tjtxWwqj42" id="content4-6">




            <div class="container">
                <div class="row justify-content-center">
                    <div class="title col-md-12 col-lg-12">
                        <h3 class="mbr-section-title mbr-fonts-style align-center mb-4 display-2 animate__animated animate__delay-1s animate__fadeInUp"><strong>How Does Java Burn Works?</strong></h3>


                    </div>
                </div>
            </div>
        </section>

        <section data-bs-version="5.1" class="content5 cid-sKy7tkCOig cid-tjtzqzBvQT" id="content5-7">

            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-12 col-lg-10">


                        <p class="mbr-text mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp">
                            This powerful formula is made up of 100% safe and natural ingredients that work together to accelerate your metabolism and provide you with essential nutrients that can help curb hunger and reduce body fat. With Java Burn, you can feel confident that you are giving your body the support it needs to achieve optimal health and wellness. Whether you are looking to lose weight, increase your energy levels, or simply feel better overall, Java Burn is the perfect solution for you.
                            <br /><br />
                            Java Burn is a dietary supplement that promises to be simple to use and highly effective. It comes in a convenient box of 30 single-serve sachets, making it easy to take with you on the go. Each sachet contains a powder that dissolves immediately when added to your coffee. This means that you can enjoy the benefits of Java Burn without any hassle or fuss. What's more, the powder has no flavor, so it won't alter the taste of your coffee in any way. This is great news for coffee-lovers who don't want to compromise on flavor or quality. With Java Burn, you can enjoy a delicious cup of coffee while boosting your metabolism and promoting weight loss.

                            <br /><br />
                            So why wait? Try Java Burn today and experience the difference for yourself!
                            <br />
                        </p>







                    </div>
                </div>
            </div>
        </section>

        <section data-bs-version="5.1" class="content8 cid-tjtzAQHko8" id="content8-8">

            <div class="container">
                <div class="row justify-content-center">
                    <div class="counter-container col-md-12 col-lg-10">

                        <div class="mbr-text mbr-fonts-style display-7">

                            <ul>





                                <li class="animate__animated animate__delay-1s animate__fadeInUp"><strong>Promotes the body's natural process of burning fat</strong></li>
                                <li class="animate__animated animate__delay-1s animate__fadeInUp"><strong>Increase Energy &amp; Metabolism</strong></li>
                                <li class="animate__animated animate__delay-1s animate__fadeInUp"><strong>Provides you with the feeling of being full</strong></li>
                                <li class="animate__animated animate__delay-1s animate__fadeInUp"><strong>Suppresses appetite</strong></li>
                                <li class="animate__animated animate__delay-1s animate__fadeInUp"><strong>Helps to stop the feeling of hunger for up to 4 hours	</strong></li>
                                <li class="animate__animated animate__delay-1s animate__fadeInUp"><strong>Java Burn is 100% all natural</strong></li>
                                <li class="animate__animated animate__delay-1s animate__fadeInUp"><strong>Made In The USA</strong>
                                </li>

                            </ul>


                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section data-bs-version="5.1" class="content4 cid-sKy7YKiKVs cid-tq5OSuAQeg" id="content4-2f">


            <div class="container">
                <div class="row justify-content-center">
                    <div class="title col-md-12 col-lg-10">
                        <h3 class="mbr-section-title mbr-fonts-style align-center mb-4 display-2 animate__animated animate__delay-1s animate__fadeInUp">
                            <strong>Limited Time Special Pricing - Act Now!
                            </strong><br />

                        </h3>
                        <h4 class="mbr-section-subtitle align-center mbr-fonts-style mb-4 display-5 animate__animated animate__delay-1s animate__fadeInUp"><strong>
                            Claim Your Discounted Java Burn Below While Stocks Last!
                        </strong></h4>

                    </div>
                </div>
            </div>
        </section>

        <section data-bs-version="5.1" class="image3 cid-tjufRGoCIV" id="image3-l">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-10">
                        <div class="image-wrapper">
                            <a onClick={toggleDialog} rel="nofollow" class="animate__animated animate__delay-1s animate__fadeInUp"><img src={require("./assets/javaburn-price.png")} alt="Java Burn Price" /></a>

                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section data-bs-version="5.1" class="content4 cid-tkw6WynXJP" id="content4-1c">




            <div class="container">
                <div class="row justify-content-center">
                    <div class="title col-md-12 col-lg-12">
                        <h3 class="mbr-section-title mbr-fonts-style align-center mb-4 display-2 animate__animated animate__delay-1s animate__fadeInUp"><strong>Java Burn Ingredients</strong></h3>


                    </div>
                </div>
            </div>
        </section>

        <section data-bs-version="5.1" class="content5 cid-sKy7tkCOig cid-tkw70l93LY" id="content5-1d">

            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-12 col-lg-10">

                        <p class="mbr-text mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp">JavaBurn is a premium coffee blend that is meticulously crafted using only the finest ingredients.
                            <br /><br />
                            Java Burn Ingredients:
                        </p><ul>
                            <li class="animate__animated animate__delay-1s animate__fadeInUp">L-Theanine</li>
                            <li class="animate__animated animate__delay-1s animate__fadeInUp">L-Carnitine</li>
                            <li class="animate__animated animate__delay-1s animate__fadeInUp">Green Coffee Bean Extract</li>
                            <li class="animate__animated animate__delay-1s animate__fadeInUp">Green Tea Leaf Extract</li>
                            <li class="animate__animated animate__delay-1s animate__fadeInUp">Vitamin B6</li>
                            <li class="animate__animated animate__delay-1s animate__fadeInUp">Vitamin B12</li>
                            <li class="animate__animated animate__delay-1s animate__fadeInUp">Vitamin D</li>
                            <li class="animate__animated animate__delay-1s animate__fadeInUp">Chromium</li>
                        </ul>


                        <br />
                    </div>
                </div>
            </div>
        </section>

        <section data-bs-version="5.1" class="features19 cid-tkw7hjl6MS" id="features20-1e">





            <div class="container">
                <div class="row justify-content-center">

                    <div class="col-12 col-md-10">
                        <div class="item mbr-flex">
                            <div class="icon-box">
                                <span class="step-number mbr-fonts-style display-5">1</span>
                            </div>
                            <div class="text-box">
                                <h4 class="icon-title card-title mbr-black mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp"><strong>L-Theanine</strong></h4>
                                <h5 class="icon-text mbr-black mbr-fonts-style display-4 animate__animated animate__delay-1s animate__fadeInUp">
                                    It is recognized to help in weight loss maintenance. According to studies, it can aid in sleep, stress reduction, and avoiding common digestive diseases while also increasing body immunity.
                                </h5>
                            </div>
                        </div>
                        <div class="item mbr-flex">
                            <div class="icon-box">
                                <span class="step-number mbr-fonts-style display-5">2</span>
                            </div>
                            <div class="text-box">
                                <h4 class="icon-title card-title mbr-black mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp"><strong>Green Tea Leaves Extract</strong></h4>
                                <h5 class="icon-text mbr-black mbr-fonts-style display-4 animate__animated animate__delay-1s animate__fadeInUp">
                                    There is a lot of evidence suggesting green tea flavonoids play a role in metabolism. Catechin, an instance, is connected to waste elimination and detoxification, allowing metabolism to work correctly.</h5>
                            </div>
                        </div>
                        <div class="item mbr-flex">
                            <div class="icon-box">
                                <span class="step-number mbr-fonts-style display-5">3</span>
                            </div>
                            <div class="text-box">
                                <h4 class="icon-title card-title mbr-black mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp"><strong>L-Carnitine</strong></h4>
                                <h5 class="icon-text mbr-black mbr-fonts-style display-4 animate__animated animate__delay-1s animate__fadeInUp">
                                    An amino acid present in many plants, L-Carnitine is a component. When consumed, it induces thermogenesis, which causes the body to lose weight without harming muscular development, joint health, or the cardiovascular system.</h5>
                            </div>
                        </div>
                        <div class="item mbr-flex">
                            <div class="icon-box">
                                <span class="step-number mbr-fonts-style display-5">4</span>
                            </div>
                            <div class="text-box">
                                <h4 class="icon-title card-title mbr-black mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp"><strong>Green Coffee</strong></h4>
                                <h5 class="icon-text mbr-black mbr-fonts-style display-4 animate__animated animate__delay-1s animate__fadeInUp">
                                    It is well known that coffee burns fat naturally, so green coffee is included in Java Burn. Caffeine is essential for staying active and energetic throughout the day.</h5>
                            </div>
                        </div>
                        <div class="item mbr-flex">
                            <div class="icon-box">
                                <span class="step-number mbr-fonts-style display-5">5</span>
                            </div>
                            <div class="text-box">
                                <h4 class="icon-title card-title mbr-black mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp"><strong>Chromium</strong></h4>
                                <h5 class="icon-text mbr-black mbr-fonts-style display-4 animate__animated animate__delay-1s animate__fadeInUp">
                                    Studies have indicated that chromium plays a crucial role in the metabolism of lipids, insulin, and glucose in the body. This essential mineral is believed to enhance the action of insulin, a hormone responsible for regulating blood sugar levels, leading to improved glucose tolerance. Research has also shown that chromium supplementation may lead to a decrease in insulin resistance, which is often linked to obesity and type 2 diabetes. Additionally, chromium can aid in weight loss by increasing lean muscle mass and decreasing body fat percentage. By improving insulin sensitivity and glucose metabolism, supplementing with chromium may help individuals achieve their weight loss goals in a healthy and effective manner.</h5>
                            </div>
                        </div>

                        <div class="item mbr-flex">
                            <div class="icon-box">
                                <span class="step-number mbr-fonts-style display-6">6</span>
                            </div>
                            <div class="text-box">
                                <h4 class="icon-title card-title mbr-black mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp"><strong>Chlorogenic Acid</strong></h4>
                                <h5 class="icon-text mbr-black mbr-fonts-style display-4 animate__animated animate__delay-1s animate__fadeInUp">
                                    Chlorogenic acid is a part of green coffee bean extract that is proven to assist in the restriction of excess carbs being digested into the system. It also decreases the number of sugar spikes, which stimulates weight gain. Chlorogenic acid also helps to control the hormones associated with obesity by cutting cholesterol and triglyceride rate in the bloodstream.</h5>
                            </div>
                        </div>

                        <div class="item mbr-flex">
                            <div class="icon-box">
                                <span class="step-number mbr-fonts-style display-7">7</span>
                            </div>
                            <div class="text-box">
                                <h4 class="icon-title card-title mbr-black mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp"><strong>Vitamin B6</strong></h4>
                                <h5 class="icon-text mbr-black mbr-fonts-style display-4 animate__animated animate__delay-1s animate__fadeInUp">
                                    Vitamin B6 is essential for fat, sugar, and protein metabolism. By doing this you burn calories and shred more quickly. This also enhances your body's ability to convert nutrients from meals.</h5>
                            </div>
                        </div>


                        <div class="item mbr-flex">
                            <div class="icon-box">
                                <span class="step-number mbr-fonts-style display-8">8</span>
                            </div>
                            <div class="text-box">
                                <h4 class="icon-title card-title mbr-black mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp"><strong>Vitamin B12</strong></h4>
                                <h5 class="icon-text mbr-black mbr-fonts-style display-4 animate__animated animate__delay-1s animate__fadeInUp">
                                    Vitamin B12 is another important component of the Java Burn formula. It gives you never-ending energy and stimulants to your metabolism. The vitamin keeps you so active the whole day that you will want to take a walk even after a long day of work.</h5>
                            </div>
                        </div>





                    </div>
                </div>
            </div>
        </section>

        <section data-bs-version="5.1" class="content4 cid-tjtKMEmhHh" id="content4-h">




            <div class="container">
                <div class="row justify-content-center">
                    <div class="title col-md-12 col-lg-12">
                        <h3 class="mbr-section-title mbr-fonts-style align-center mb-4 display-2 animate__animated animate__delay-1s animate__fadeInUp"><strong>60-Days Money Back Guarantee</strong></h3>


                    </div>
                </div>
            </div>
        </section>

        <section data-bs-version="5.1" class="header11 cid-tjtKXxqvPZ" id="header11-j">






            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-5 image-wrapper">
                        <img class="w-100 animate__animated animate__delay-1s animate__fadeInUp" src={require("./assets/60-days-money-back-guarantee-680x450.png")} alt="Money Back Guarantee" />
                    </div>
                    <div class="col-12 col-md">
                        <div class="text-wrapper text-center">

                            <p class="mbr-text mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp">You can use Java Burn Supplement for a whole 60 days and use your right to ask for a refund anytime during this period. If you’re unsatisfied with the results in any way, let us know and claim your money back!
                                <br /><br />
                                Consider this a 2-month test run. If you don’t like it - get your investment back! Order now to secure your 60 Day Money Back Guarantee with Java Burn Supplement.<br /></p>
                            <div class="mbr-section-btn mt-3"><a class="btn btn-warning display-7 animate__animated animate__delay-1s animate__fadeInUp" onClick={toggleDialog} rel="nofollow"><span class="mobi-mbri mobi-mbri-arrow-next mbr-iconfont mbr-iconfont-btn" style={{ fontSize: "15px;" }}></span>GET YOUR BOTTLE TODAY</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section data-bs-version="5.1" class="content4 cid-tkwewnI2cJ" id="content4-1j">




            <div class="container">
                <div class="row justify-content-center">
                    <div class="title col-md-12 col-lg-12">
                        <h3 class="mbr-section-title mbr-fonts-style align-center mb-4 display-2 animate__animated animate__delay-1s animate__fadeInUp"><strong>Java Burn Supplement</strong></h3>


                    </div>
                </div>
            </div>
        </section>


        <section data-bs-version="5.1" class="content5 cid-sKy7tkCOig cid-tkwhoi5CpZ" id="content5-1m">

            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-12 col-lg-10">


                        <p class="mbr-text mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp">

                            Java Burn is a weight loss formula that offers numerous health benefits apart from its primary goal of helping people lose weight. The natural ingredients used in Java Burn have been carefully selected to regulate blood pressure levels, manage blood sugar, and boost metabolism, which are all crucial in preventing weight gain. This product is designed to promote overall wellness, and the natural ingredients used in its formula ensure that it is safe and effective. Java Burn is an excellent option for people looking to lose weight while also improving their overall health. Its natural approach to weight loss is a refreshing change from other weight loss products available in the market today.
                            <br /><br />
                            Java Burn is an all-natural product that has gained popularity in the weight loss market due to its ability to increase metabolism and reduce cravings. It's important to understand that metabolism plays a crucial role in weight management. It's responsible for burning calories and ensuring that you consume fewer calories than you burn. If you want to lose weight and shed those extra pounds, you need to boost your metabolic rate either through exercise or by incorporating natural components like Java Burn into your diet.
                            <br /><br />
                            Java Burn works by increasing the body's ability to burn more calories throughout the day. It contains powerful natural ingredients that have been proven to boost metabolism and reduce cravings. By increasing the metabolic rate, Java Burn helps the body burn more fat and lose weight. It also helps to suppress appetite, reducing the urge to eat frequently, which is a significant factor in managing weight.
                            <br /><br />
                            The natural components in Java Burn include green coffee bean extract, chlorogenic acid, and garcinia cambogia. These ingredients have been shown to be effective in promoting weight loss by increasing metabolism and reducing cravings. Additionally, Java Burn is free from harmful chemicals and artificial additives, making it a safe and healthy way to achieve your weight loss goals.
                            <br /><br />
                            In conclusion, Java Burn is a natural product that can help you lose weight by increasing metabolism and reducing cravings. It's essential to understand that a boosted metabolic rate is crucial for successful weight management. By incorporating Java Burn into your daily routine, you can achieve your desired weight loss goals in a healthy and natural way.
                            <br /></p>
                    </div>
                </div>
            </div>
        </section>

        <section data-bs-version="5.1" class="image3 cid-tjufRGoCIV" id="image3-l">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-10">
                        <div class="image-wrapper">
                            <a onClick={toggleDialog} rel="nofollow" class="animate__animated animate__delay-1s animate__fadeInUp"><img src={require("./assets/javaburn.jpg")} alt="Java Burn" /></a>

                        </div>
                    </div>
                </div>
            </div>
        </section>




        <section data-bs-version="5.1" class="content4 cid-sKy7YKiKVs cid-tq5PtlefNK" id="content4-2g">

            <div class="container">
                <div class="row justify-content-center">
                    <div class="title col-md-12 col-lg-10">
                        <h3 class="mbr-section-title mbr-fonts-style align-center mb-4 display-2 animate__animated animate__delay-1s animate__fadeInUp">
                            <strong>Limited Time Special Pricing - Act Now!
                            </strong><br />

                        </h3>
                        <h4 class="mbr-section-subtitle align-center mbr-fonts-style mb-4 display-5 animate__animated animate__delay-1s animate__fadeInUp"><strong>
                            Secure Your Java Burn Bottles While Stocks Last
                        </strong></h4>

                    </div>
                </div>
            </div>
        </section>

        <section data-bs-version="5.1" class="image3 cid-tkwhJbxu0R" id="image3-1o">

            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-10">
                        <div class="image-wrapper">
                            <a onClick={toggleDialog} rel="nofollow" class="animate__animated animate__delay-1s animate__fadeInUp"><img src={require("./assets/javaburn-price.png")} alt="Java Burn Price" /></a>

                        </div>
                    </div>
                </div>
            </div>
        </section>




        <section data-bs-version="5.1" class="content4 cid-sKy7YKiKVs cid-tq5PtlefNK" id="content4-3g">

            <div class="container">
                <div class="row justify-content-center">
                    <div class="title col-md-12 col-lg-10">
                        <h3 class="mbr-section-title mbr-fonts-style align-center mb-4 display-2 animate__animated animate__delay-1s animate__fadeInUp">
                            <strong>Benefits of JavaBurn Supplement
                            </strong><br />

                        </h3>
                    </div>
                </div>
            </div>
        </section>

        <section data-bs-version="5.1" class="image3 cid-tkwhJbxu0R" id="image3-1o">

            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-10">
                        <p class="mbr-text mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp">
                            JavaBurn is a premium quality coffee blend that offers a range of health benefits.
                            <br /><br />
                            One of the most notable benefits of Java Burn is that it helps to increase metabolism, which allows the body to burn fat and calories more quickly. This can be especially beneficial for those who are looking to lose weight or manage their weight more effectively.
                            <br /><br />
                            Additionally, JavaBurn can help to reduce cravings, which can make it easier to avoid overeating and stick to a healthy diet plan. This, in turn, can also contribute to weight loss and overall health improvement.
                            <br /><br />
                            Finally, JavaBurn provides natural energy, which can help to keep you focused and alert throughout the day. This can be especially helpful for those who lead busy lifestyles or have demanding work schedules.
                            <br /><br />
                            Overall, JavaBurn is an excellent choice for anyone looking to improve their health and well-being in a natural and sustainable way.
                        </p>


                    </div>
                </div>
            </div>
        </section>


        <section data-bs-version="5.1" class="content8 cid-tjtzAQHko8" id="content8-8">

            <div class="container">
                <div class="row justify-content-center">
                    <div class="counter-container col-md-12 col-lg-10">

                        <div class="mbr-text mbr-fonts-style display-7">

                            <ul>


                                <li class="animate__animated animate__delay-1s animate__fadeInUp"><strong>Keeps Your Hormones Balanced</strong></li>

                                <li class="animate__animated animate__delay-1s animate__fadeInUp"><strong>Balances Blood Pressure</strong>
                                </li>

                                <li class="animate__animated animate__delay-1s animate__fadeInUp">
                                    <strong>​It Helps You Stay in Perfect Health</strong>
                                </li>

                                <li class="animate__animated animate__delay-1s animate__fadeInUp">
                                    <strong>​Helps You Lose Weight Naturally</strong>
                                </li>
                                <li class="animate__animated animate__delay-1s animate__fadeInUp">
                                    <strong>​Enhances The Immune System</strong>
                                </li>

                            </ul>


                        </div>
                    </div>
                </div>
            </div>
        </section>




        <section data-bs-version="5.1" class="content4 cid-tkwje8UTle" id="content4-1p">

            <div class="container">
                <div class="row justify-content-center">
                    <div class="title col-md-12 col-lg-12">
                        <h3 class="mbr-section-title mbr-fonts-style align-center mb-4 display-2 animate__animated animate__delay-1s animate__fadeInUp"><strong>Java Burn Frequently Asked Questions</strong></h3>


                    </div>
                </div>
            </div>
        </section>

        <section data-bs-version="5.1" class="content16 cid-tkwjoCkNd5" id="content16-1q">





            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10">

                        <div id="bootstrap-accordion_24" class="panel-group accordionStyles accordion" role="tablist" aria-multiselectable="true">
                            <div class="card mb-3">
                                <div class="card-header" role="tab" id="headingOne">
                                    <a role="button" class="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="/#collapse1_24" aria-expanded="false" aria-controls="collapse1">
                                        <h6 class="panel-title-edit mbr-fonts-style mb-0 display-7"><strong>How much Java Burn should I order?</strong></h6>
                                        <span class="sign mbr-iconfont mbri-arrow-down"></span>
                                    </a>
                                </div>
                                <div id="collapse1_24" class="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" data-bs-parent="#bootstrap-accordion_24">
                                    <div class="panel-body">
                                        <p class="mbr-fonts-style panel-text display-4">According to recent research, it is recommended to consistently consume Java Burn for a minimum of 90 to 180 days to achieve the best results.
                                            <br /><br />This means that the longer and more consistently you take Java Burn, the more you will benefit from its unique blend of natural ingredients. Therefore, it is highly recommended to take advantage of the best deal 180 day supply package or the 90 day deep-discount package, which are almost just as popular.<br /><br /> The special pricing is only guaranteed for today or until our limited inventory runs out, and we're selling out of our current stock incredibly fast. <br /><br />We assure you that you'll never be able to buy Java Burn cheaper than today, which is another compelling reason to take advantage of this exclusive offer. Don't wait too long or risk missing out on the benefits of this amazing supplement.

                                            <br /></p>
                                    </div>
                                </div>
                            </div>
                            <div class="card mb-3">
                                <div class="card-header" role="tab" id="headingOne">
                                    <a role="button" class="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="/#collapse2_24" aria-expanded="false" aria-controls="collapse2">
                                        <h6 class="panel-title-edit mbr-fonts-style mb-0 display-7"><strong>Is Java Burn Safe?</strong></h6>
                                        <span class="sign mbr-iconfont mbri-arrow-down"></span>
                                    </a>
                                </div>
                                <div id="collapse2_24" class="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" data-bs-parent="#bootstrap-accordion_24">
                                    <div class="panel-body">
                                        <p class="mbr-fonts-style panel-text display-4">
                                            Java Burn is absolutely 100% natural , safe and effective. Many thousands of folks enjoy taking Java Burn every day and there have been absolutely zero side effects reported. Every packet of Java Burn is manufactured here in the USA in our state of the art FDA approved and GMP (good manufacturing practices) certified facility under the most sterile, strict and precise standards. Java Burn is 100% all natural, vegetarian, gluten-free and non-GMO. As always, if you have a medical condition it's recommended to consult with your doctor.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card mb-3">
                                <div class="card-header" role="tab" id="headingOne">
                                    <a role="button" class="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="/#collapse2_24" aria-expanded="false" aria-controls="collapse2">
                                        <h6 class="panel-title-edit mbr-fonts-style mb-0 display-7"><strong>Will Java Burn work for me?</strong></h6>
                                        <span class="sign mbr-iconfont mbri-arrow-down"></span>
                                    </a>
                                </div>
                                <div id="collapse2_24" class="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" data-bs-parent="#bootstrap-accordion_24">
                                    <div class="panel-body">
                                        <p class="mbr-fonts-style panel-text display-4">
                                            Yes! Java Burn is a revolutionary product that has taken the health industry by storm. It is the first and only natural formula in the world that is designed to enhance the metabolism when combined with coffee. What makes Java Burn unique is its 100% safe and natural composition, which has been scientifically proven to deliver unparalleled fat burning results. If you are looking to lose weight and boost your energy levels, Java Burn is the way to go. With its proprietary patent-pending formula, you can be assured of its effectiveness and safety. Say goodbye to fad diets and unhealthy weight loss methods and switch to Java Burn for a healthier and more sustainable weight loss journey.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card mb-3">
                                <div class="card-header" role="tab" id="headingOne">
                                    <a role="button" class="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="/#collapse3_24" aria-expanded="false" aria-controls="collapse3">
                                        <h6 class="panel-title-edit mbr-fonts-style mb-0 display-7"><strong>Is there a refund policy for my transaction?</strong></h6>
                                        <span class="sign mbr-iconfont mbri-arrow-down"></span>
                                    </a>
                                </div>
                                <div id="collapse3_24" class="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" data-bs-parent="#bootstrap-accordion_24">
                                    <div class="panel-body">
                                        <p class="mbr-fonts-style panel-text display-4">
                                            If you’re not satisfied, we’ll gladly give you a full refund. Simply contact our support team, and we’ll refund you 100%. That’s how confident we are that you’ll love your supply of Java Burn.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card mb-3">
                                <div class="card-header" role="tab" id="headingOne">
                                    <a role="button" class="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="/#collapse4_24" aria-expanded="false" aria-controls="collapse4">
                                        <h6 class="panel-title-edit mbr-fonts-style mb-0 display-7"><strong>Is this a one time payment?</strong></h6>
                                        <span class="sign mbr-iconfont mbri-arrow-down"></span>
                                    </a>
                                </div>
                                <div id="collapse4_24" class="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" data-bs-parent="#bootstrap-accordion_24">
                                    <div class="panel-body">
                                        <p class="mbr-fonts-style panel-text display-4">
                                            Yes, your order today is a one time payment with no auto ship subscriptions or hidden charges.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card mb-3">
                                <div class="card-header" role="tab" id="headingOne">
                                    <a role="button" class="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="/#collapse5_24" aria-expanded="false" aria-controls="collapse5">
                                        <h6 class="panel-title-edit mbr-fonts-style mb-0 display-7"><strong>How long would it take to receive the product to my delivery address?</strong></h6>
                                        <span class="sign mbr-iconfont mbri-arrow-down"></span>
                                    </a>
                                </div>
                                <div id="collapse5_24" class="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" data-bs-parent="#bootstrap-accordion_24">
                                    <div class="panel-body">
                                        <p class="mbr-fonts-style panel-text display-4">
                                            We ship out orders the same day they are received. If you're in the US or Canada you can expect the package in 5 to 7 business days. If you're outside the US it'll be longer depending on your local carriers. International orders typically take 8 – 15 business days (plus customs clearance time). </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card mb-3">
                                <div class="card-header" role="tab" id="headingOne">
                                    <a role="button" class="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="/#collapse6_24" aria-expanded="false" aria-controls="collapse6">
                                        <h6 class="panel-title-edit mbr-fonts-style mb-0 display-7"><strong>Will Java Burn affect my coffee in any way?</strong></h6>
                                        <span class="sign mbr-iconfont mbri-arrow-down"></span>
                                    </a>
                                </div>
                                <div id="collapse6_24" class="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" data-bs-parent="#bootstrap-accordion_24">
                                    <div class="panel-body">
                                        <p class="mbr-fonts-style panel-text display-4">
                                            Absolutely not. Java Burn is totally tasteless and dissolves instantly into your coffee… And it works just as well regardless of what kind of coffee you drink or what you like to put in it.

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section data-bs-version="5.1" class="content4 cid-tkwna90fpy" id="content4-1r">




            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="title col-md-12 col-lg-12">
                        <h3 class="mbr-section-title mbr-fonts-style align-center mb-4 display-2 animate__animated animate__delay-1s animate__fadeInUp"><strong>What Happens When You Click The “Buy Now" Button?</strong></h3>


                    </div>
                </div>
            </div>
        </section>

        <section data-bs-version="5.1" class="content5 cid-sKy7tkCOig cid-tkwnPQJZos" id="content5-1s">

            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-12 col-lg-10">


                        <p class="mbr-text mbr-fonts-style display-7 animate__animated animate__delay-1s animate__fadeInUp">Once you have clicked the Java Burn “Buy Now” button that is right below this text, you will be taken to the secure checkout page. Just enter your information, and you will then be given instant access to the entire Java Burn supplement.<br /></p>
                    </div>
                </div>
            </div>
        </section>


        <section data-bs-version="5.1" class="content8 cid-tlsUZTTbCi" id="content8-20">

            <div class="container">
                <div class="row justify-content-center">
                    <div class="counter-container col-md-12 col-lg-10">

                        <div class="mbr-text mbr-fonts-style display-7">
                            <ul>
                                <li class="animate__animated animate__delay-1s animate__fadeInUp"><strong>Is My Credit Card Information Safe?&nbsp;</strong><br />Your online privacy is one thing you can be sure we so much prioritize here and thus do not worry about losing any sensitive credentials while making your purchase Java Burn from us. Besides, you can bank on ClickBank’s excellent reputation and vast experience in online transactions to help you in safeguarding your purchase.</li><li class="animate__animated animate__delay-1s animate__fadeInUp"><strong>Java Burn Pricing:</strong><span style={{ fontSize: "19.2px" }}><br />As of today, Java Burn is available at a massive discount from its original price:&nbsp;</span><br />1 Bottle: $69 each + free shipping. <br />3 Bottles: $39 each + free shipping. <br />6 Bottles: $34 each + free shipping. <br />So Hurry Up! and Secure your Java Burn supplement while Stocks LAST.</li><li class="animate__animated animate__delay-1s animate__fadeInUp"><strong>Refund Policy:</strong><br />If within the first 60 days of receipt you are not satisfied with Java Burn, you can request a refund by sending an email to the address given inside the product and we will immediately refund your entire purchase price, with no questions asked.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section data-bs-version="5.1" class="content4 cid-tkwqEwso5U" id="content4-1v">




            <div class="container">
                <div class="row justify-content-center">
                    <div class="title col-md-12 col-lg-12">
                        <h3 class="mbr-section-title mbr-fonts-style align-center mb-4 display-2 animate__animated animate__delay-1s animate__fadeInUp"><strong>Order Your Discounted Java Burn Bottle Now!</strong></h3>


                    </div>
                </div>
            </div>
        </section>

        <section data-bs-version="5.1" class="image3 cid-tkwr01VcII" id="image3-1x">





            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-6">
                        <div class="image-wrapper">
                            <a onClick={toggleDialog} rel="nofollow" class="animate__animated animate__delay-1s animate__fadeInUp"><img src={require("./assets/javaburn-buy.png")} alt="Java Burn Buy Now" /></a>

                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section data-bs-version="5.1" class="content4 cid-tkwttKNYNK" id="content4-1y">




            <div class="container">
                <div class="row justify-content-center">
                    <div class="title col-md-12 col-lg-8">

                        <h4 class="mbr-section-subtitle align-center mbr-fonts-style mb-4 display-5 animate__animated animate__delay-1s animate__fadeInUp"><strike>Regular Price: $197/per bottle</strike><br /><strong><font size="7">Today's Price: $39/per bottle</font></strong></h4>
                        <div class="mbr-section-btn align-center"><a class="btn btn-warning display-5 animate__animated animate__delay-1s animate__fadeInUp" onClick={toggleDialog} rel="nofollow"><span class="mobi-mbri mobi-mbri-arrow-next mbr-iconfont mbr-iconfont-btn" style={{ fontSize: "15px" }}></span>GET 75% DISCOUNT NOW</a></div>
                    </div>
                </div>
            </div>
        </section>

        <section data-bs-version="5.1" class="content13 cid-tq5VQhQx8w" id="content13-2h">


            <div class="container-fluid">
                <div class="row justify-content-center">

                </div>
                <div class="row justify-content-center">
                    <div class="col-md-12 col-lg-8">
                        <div class="row justify-content-center">
                            <div class="col-12 col-lg-6">
                                <ul class="list mbr-fonts-style display-7">

                                    <li class="animate__animated animate__delay-1s animate__fadeInUp"><a href="/#content4-4" class="text-white text-primary animate__animated animate__delay-1s animate__fadeInUp"><strong>What is Java Burn?</strong></a></li>
                                    <li class="animate__animated animate__delay-1s animate__fadeInUp"><a href="/#content4-6" class="text-white text-primary animate__animated animate__delay-1s animate__fadeInUp"><strong>How Does Java Burn Work?</strong></a></li>
                                    <li class="animate__animated animate__delay-1s animate__fadeInUp"><a href="/#content4-1c" class="text-white text-primary animate__animated animate__delay-1s animate__fadeInUp"><strong>Ingredients of Java Burn</strong></a></li>
                                    <li class="animate__animated animate__delay-1s animate__fadeInUp"><a href="/#content4-1j" class="text-white text-primary animate__animated animate__delay-1s animate__fadeInUp"><strong>Java Burn Supplement</strong></a></li>
                                </ul>
                            </div>
                            <div class="col-12 col-lg-6">
                                <ul class="list mbr-fonts-style display-7">


                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section data-bs-version="5.1" class="footer3 cid-sFnyUkxVUQ cid-tq5NrQKsDg" once="footers" id="footer3-2e">



            <div class="container-fluid">
                <div class="media-container-row align-center mbr-white">

                    <div class="row social-row">
                        <div class="social-list align-left pb-2">






                            <div class="soc-item">
                                <p class="animate__animated animate__delay-1s animate__fadeInUp">Please note that the information we provide is not intended to replace consultation with a qualified medical professional. We encourage you to inform your physician of changes you make to your lifestyle and discuss these with him or her. For questions or concerns about any medical conditions you may have, please contact your doctor.</p>

                                <p class="animate__animated animate__delay-1s animate__fadeInUp">Statements on this website have not been evaluated by the Food and Drug Administration. Products are not intended to diagnose, treat, cure or prevent any disease. If you are pregnant, nursing, taking medication, or have a medical condition, consult your physician before using our products.</p>

                                <p class="animate__animated animate__delay-1s animate__fadeInUp">The website’s content and the product for sale is based upon the author’s opinion and is provided solely on an “AS IS” and “AS AVAILABLE” basis. You should do your own research and confirm the information with other sources when searching for information regarding health issues and always review the information carefully with your professional health care provider before using any of the protocols presented on this website and/or in the product sold here.</p>

                                <p class="animate__animated animate__delay-1s animate__fadeInUp">ClickBank is the retailer of products on this site. CLICKBANK® is a registered trademark of Click Sales, Inc., a Delaware corporation located at 1444 South Entertainment Ave, Suite 410, Boise, Idaho, 83709, USA and used by permission. ClickBank’s role as retailer does not constitute an endorsement, approval or review of these products or any claim, statement or opinion used in promotion of these products. *For international shipping (outside of the United States), shipping fees will apply.</p>


                            </div></div>
                    </div>
                    <div class="row row-copirayt">
                        <p class="mbr-text mb-0 mbr-fonts-style mbr-white align-center display-4 animate__animated animate__delay-1s animate__fadeInUp">
                            © Copyright 2024 Java Burn. All Rights Reserved.
                        </p>
                    </div>
                </div>
            </div>
        </section>

    </div>
}