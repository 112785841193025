// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA50sZuHGw0mGpd4MEwUrHPPIqjzE3O3d0",
  authDomain: "reason-reviews.firebaseapp.com",
  databaseURL: "https://reason-reviews-default-rtdb.firebaseio.com",
  projectId: "reason-reviews",
  storageBucket: "reason-reviews.appspot.com",
  messagingSenderId: "841117097263",
  appId: "1:841117097263:web:9895ca3c4d99a7ad6ed5da",
  measurementId: "G-1D81ZE00BD"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
export const firebaseDb = getFirestore(firebaseApp)
export default firebaseApp;