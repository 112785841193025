import {
    collection,
    getDocs,
    doc,
query,
deleteDoc,
where
} from "firebase/firestore";
import {  useState } from "react";
import { useParams } from "react-router-dom";
import { firebaseDb } from "./configuration-firebase";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
export const Unsubscribe=()=>{
    const { product } = useParams();
    const [isDelted,setIsDeleted] = useState(false)
    const [formData, setFormData] = useState({
        email: "",
        name: product
    });
    const [formDataError, setFormDataError] = useState({
        email: "",
        name: ""
    })

    const updateForm = (event) => {
        // debugger
        setFormDataError({
            name: ""
            , email: ""
        })
        formData[event.target.name] = event.target.value

        setFormData(formData)

    }


    const handlesubmitt = async () => {
        console.log("submitt")
        if (!formData.name || formData.name === "") {
            setFormDataError({
                name: "Name is required!"
            })
            return;
        }
        if (!formData.email || formData.email === "") {
            setFormDataError({
                email: "Email is required!"
            })
            return;
        }
        // if(!validateEmail(formData.validateEmail)){
        //     setFormDataError({
        //         email:"not a valid email!"
        //     })
        //     return;
        // }
        deleteEmail()
    }
  
const deleteEmail = async() =>{
    const usersCollectionRef = collection(firebaseDb, "emails");
    debugger
    const q = query(usersCollectionRef, where("product", "==", product),where("email", "==", formData.email.toLowerCase()));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((result) => {
        deleteDoc(doc(firebaseDb,"emails",result.id))
        console.log(result.id, ' => ', result.data());
    });
    setIsDeleted(true);
}
   
return(<div>
 <Modal show={true} >

<Modal.Header  style={{ background: "#dd6d00", color: "white" }} >
    <Modal.Title style={{ fontWeight: "bold" }}>Unsubscribe now</Modal.Title>
</Modal.Header>

<Modal.Body>
    {isDelted ? <div>You are unsubscribed! </div>:
    <Form onSubmit={handlesubmitt}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Product</Form.Label>
            <Form.Control type="text" placeholder="name" value={formData['name']} required name="name"isInvalid={formDataError['name'] !== ""} />
            <Form.Control.Feedback type="invalid">{formDataError['name']} </Form.Control.Feedback>

        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="email" required name="email" onChange={updateForm} isInvalid={formDataError['email'] !== ""} />
            <Form.Control.Feedback type="invalid">{formDataError['email']} </Form.Control.Feedback>

        </Form.Group>

    </Form>}
</Modal.Body>

{!isDelted &&<Modal.Footer style={{ background: "#dd6d00" }}>
    <div class="navbar-buttons mbr-section-btn" onClick={handlesubmitt}><a class="btn btn-warning display-4" rel="nofollow"><span class="socicon socicon-bale mbr-iconfont mbr-iconfont-btn" style={{ fontSize: "20px" }}></span>UNSUBSCRIBE NOW</a></div>
</Modal.Footer>}

</Modal>
</div>
)
}