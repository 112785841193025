import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Template1 } from './template1/Template-1';
import { Route, Routes } from 'react-router-dom';
import { Unsubscribe } from './Unsubscibe';
import { Redirect } from './Redirect';


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path={'template1/:product'} element={<Template1/>}/>
        <Route path={'unsubscribe/:product'} element={<Unsubscribe/>}/>
        <Route path={'offers/:product'} element={<Template1/>}/>
      </Routes>
      

    </div>
  );
}

export default App;
